import React from "react";
import * as UI from "@goodgym/components";

export type BannerProps = {
  title?: string;
  tagline?: string | null;
  buttonText?: string;
  image?: string;
  linkForButton?: string;
  logo?: string;
};

const LandingHeader = ({ image }: { image: string | undefined }) => (
  <UI.Box
    sx={{
      height: {xs:"300px", md:"400px"},
      width: "100%",
      backgroundImage: `url(${image})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      position: "relative",
      "@media (min-width: 768px)": {
        "&:before": {
          position: "absolute",
          top: 0,
          left: 0,
          content: '""',
          height: "100%",
          width: "9rem",
          ml:-0.19,
          backgroundImage:
            "linear-gradient(to left top, transparent, transparent 50%, rgb(51, 51, 51) 51%, rgb(51, 51, 51))",
        },
      },
      "@media (max-width: 767px)": {
        "&:before": {
          position: "absolute",
          bottom: "-0.9px",
          left: "0px",
          content: '""',
          width: "100%",
          height: "4rem",
          backgroundImage:
            "linear-gradient(to bottom left, transparent, transparent 50%, #333 51%, #333)",
        },
      },
    }}
  ></UI.Box>
);

const Banner: React.FC<BannerProps> = ({
  title,
  tagline,
  buttonText,
  linkForButton,
  image,
  logo,
}) => {
  return (
    <UI.Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column-reverse", md: "row" },
      }}
    >
      <UI.Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "rgb(51, 51, 51)",
        }}
      >
        {logo && (
          <img
            src={logo}
            alt="orgLogo"
            style={{ height: "100px", width: "100px", marginTop:5, marginBottom:2 }}
          />
        )}
        <UI.Typography
          variant="h2"
          color="#FFF"
          sx={{
            typography: "h2",
            textTransform: "uppercase",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 2,
          }}
        >
          {title}
        </UI.Typography>

        <UI.Typography
          variant="h5"
          color="#FFF"
          sx={{
            lineHeight: "1.5",
            my: 3,
            fontWeight: "normal",
            textAlign: "center",
            width: "100%",
          }}
        >
          {tagline}
        </UI.Typography>

        {buttonText && (
          <UI.Container sx={{ textAlign: "center" }}>
            <UI.Button
              variant="contained"
              color="primary"
              href={linkForButton}
              sx={{ height: "50px", padding: 2, mb: 3 }}
            >
              {buttonText}
            </UI.Button>
          </UI.Container>
        )}
      </UI.Container>
      <LandingHeader image={image} />
    </UI.Box>
  );
};

export default Banner;
