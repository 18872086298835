import * as UI from "@goodgym/components";
import { useTheme } from "@goodgym/components";
import SessionSignupDialog from "@goodgym/components/SessionSignupDialog";
import { useSessionPageQuery } from "@goodgym/graphql/hooks";
import * as Icons from "@goodgym/icons";
import * as u from "@goodgym/util";
import React from "react";
import { useParams } from "react-router-dom";
import * as C from "../SessionPage/components";
import LocationCard from "./components/LocationCard";
import Testimonial1 from "./testimonial1.png";
import Testimonial2 from "./testimonial2.png";
import { useAuth } from "@goodgym/auth";
import { useSignUpToSession } from "@goodgym/graphql/mutations";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as T from "@goodgym/graphql/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const testimonials = [
  {
    quote:
      "GoodGym is a wonderful initiative. It combines three of the most powerful ways of uplifting your own wellbeing – exercising, socialising, and, above all, doing good for others",
    name: "Professor Lord Richard",
    image: Testimonial1,
  },
  {
    quote:
      "All of our tasks are fun, but this one really felt like we were having a clear and direct impact on those in need, and whilst packing the bags we could literally imagine a child opening their bag on Christmas Day.",
    name: "Rachel",
    image: Testimonial2,
  },
];

const EasySignUpPage = () => {
  const { slug } = useParams();
  const { data } = useSessionPageQuery({
    variables: { slug: slug! },
    skip: !slug,
  });
  const navigate = useNavigate();
  const [param] = useSearchParams();

  const { runner } = useAuth();

  const theme = useTheme();
  const md = UI.useMediaQuery(theme.breakpoints.up("md"));
  const [showComponent, setShowComponent] = React.useState(false);

  const showSignupModal = () => setShowComponent(true);
  const hideSignupModal = () => setShowComponent(false);

  const { session } = data || {}; // Provide a default empty object

  const hasSignedUpTrained =
    session?.signedUp &&
    runner &&
    (u.training.completed(runner, "GROUP_SESSION") ||
      u.training.passed(runner, "GROUP_SESSION"));

  const completeTraining =
    !session?.signedUp &&
    runner &&
    (u.training.completed(runner, "GROUP_SESSION") ||
      u.training.passed(runner, "GROUP_SESSION"));

  const taskforceRoles = session?.taskforceRoles.filter((r) =>
    param.getAll("tf-role-id").includes(r.id)
  );
  // Ensure session and taskforceRoles always have default values
  const safeSession = session || ({} as T.SessionCardFragment);
  const safeTaskforceRoles = taskforceRoles || [];

  const signUpToSession = useSignUpToSession({
    taskforceRoles: safeTaskforceRoles,
    session: safeSession,
    easySignUp: true,
  });

  const runSignUpToSession = async () => {
    if (!session || session.signedUp) return;
    await signUpToSession();
    navigate(`/v3/signed-up/${slug}/?name=${runner?.name}`);
  };
 const runnerSignUp = async () => {
   if (!session || session.signedUp) return;
   await signUpToSession();
   showSignupModal();
 };
  if (!data) {
    return <UI.Loading />;
  }

  return (
    <UI.Box sx={{}}>
      <UI.Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <UI.Box sx={{ width: "40%", mt: 2, textAlign: "center" }}>
          <UI.Logo easySignup={true} height={60} />
        </UI.Box>

        <UI.Box sx={{ width: "60%", textAlign: "center" }}>
          {" "}
          {session?.organisation && (
            <UI.Typography
              variant="h1"
              sx={{
                mb: 3,
                mt: 2,
                px: 1,
                fontStyle: "normal",
                fontFamily: "'Barlow Condensed', sans-serif",
                textTransform: "uppercase",

                fontSize: { xs: "30px", md: "40px" },
              }}
              color="primary"
            >
              📌 {session.organisation?.name}
            </UI.Typography>
          )}
        </UI.Box>
      </UI.Box>

      <UI.Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          mt: { md: 4 },
        }}
      >
        <UI.Box
          sx={{
            mx: { xs: 0, md: 2 },
            width: { xs: "100%", md: "40%" },
            overflow: { xs: "visible", md: "hidden" },
            height: { xs: "auto", md: "auto" },

            top: { xs: "auto", md: "0" },
            textAlign: "center",
          }}
        >
          <UI.Container>
            <UI.Box
              component="img"
              src={session?.imageUrl}
              sx={{
                width: { xs: "auto", md: "auto" },
                maxWidth: "100%",
                height: { xs: "450px", md: "590px" },
                borderRadius: "10px",
                objectFit: "cover",
                mt: 1,
              }}
            />
          </UI.Container>

          <UI.Container
            sx={{
              my: 3,
              width: { xs: "100%", md: "100%" },
              textAlign: "center",
              display: { xs: "none", md: "block" },
            }}
          >
            {hasSignedUpTrained ? (
              <UI.Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "primary.main" }}
              >
                Congratulations, you're going!
              </UI.Typography>
            ) : (
              <UI.Button
                fullWidth
                onClick={
                  completeTraining
                    ? runSignUpToSession
                    : runner && !session?.signedUp
                    ? runnerSignUp
                    : showSignupModal
                }
                sx={{
                  fontSize: "34px",
                }}
              >
                Join this open session
              </UI.Button>
            )}
            {showComponent && session && (
              <SessionSignupDialog
                onClose={hideSignupModal}
                open={showComponent}
                session={session}
              />
            )}
          </UI.Container>
          <UI.Container sx={{ my: 1, display: { xs: "none", md: "block" } }}>
            <UI.Grid container spacing={3} sx={{}}>
              {testimonials.map((quote, index) => (
                <UI.Grid item xs={12} md={12} lg={6} key={index}>
                  <UI.Card
                    sx={{ height: { md: "370px", lg: "400px", xl: "365px" } }}
                  >
                    <UI.Box
                      sx={{
                        borderTop: "4px solid #E11018",
                      }}
                    />
                    <UI.Box
                      sx={{
                        p: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <UI.Avatar
                        src={quote.image}
                        sx={{ height: "120px", width: "120px" }}
                      />
                      <UI.Box sx={{ display: "flex" }}>
                        <UI.Typography variant="h5" sx={{ mr: 1, mt: 1 }}>
                          ||
                        </UI.Typography>
                        <UI.Typography variant="body1" sx={{ mt: 2 }}>
                          {quote.quote}
                        </UI.Typography>
                      </UI.Box>

                      <UI.Typography
                        variant="body1"
                        color={"primary.main"}
                        sx={{ mt: 2, textAlign: "right" }}
                      >
                        {quote.name}
                      </UI.Typography>
                    </UI.Box>
                  </UI.Card>
                </UI.Grid>
              ))}
            </UI.Grid>
          </UI.Container>
        </UI.Box>
        <UI.Box
          sx={{
            width: { xs: "100%", md: "60%" },
            mx: 0,
            px: 2,
            mb: "120px",
          }}
        >
          <UI.Box
            sx={{
              minHeight: { xs: "auto", md: "500px" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <UI.Typography
              variant="h1"
              sx={{
                mb: 0.5,
                px: 0,
                fontStyle: "normal",
                fontFamily: "'Barlow Condensed', sans-serif",
                textTransform: "uppercase",
                fontSize: { xs: "40px", md: "70px" },
              }}
            >
              {session?.title}
            </UI.Typography>

            <UI.Typography
              variant="subtitle1"
              sx={{
                mt: 1,
                mb: 2,
                px: 0,
                fontStyle: "italic",
                fontFamily: "'Barlow Condensed', sans-serif",
                lineHeight: { xs: "20px", md: "30px" },
                fontSize: { xs: "20px", md: "30px" },
              }}
            >
              {session?.strapline}
            </UI.Typography>

            <UI.Box
              sx={{
                textAlign: "left",
                display: "flex",
                border: "1px solid rgba(225, 16, 24, 0.3)", // Light red border
                boxShadow: "0px 6px 25px rgba(225, 16, 24, 0.3)", // Stronger red shadow effect
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <Icons.CalendarToday
                sx={{
                  fontSize: { xs: "50px", md: "70px" },
                  pr: 1,
                  mt: 1,
                }}
              />
              <UI.Box>
                {" "}
                <UI.Typography
                  variant="h2"
                  sx={{
                    mb: 0.5,
                    px: { xs: 0, md: 0.5 },
                    fontStyle: "normal",
                    fontFamily: "'Barlow Condensed', sans-serif",
                    textTransform: "uppercase",
                    mt: 1,
                    fontColor: "white",
                    fontSize: { xs: "30px", md: "34px" },
                  }}
                >
                  {u.time.format(session?.startedAt, "EEEE do MMMM yyyy")}
                </UI.Typography>
                <UI.Typography
                  variant="h4"
                  sx={{
                    mb: 0.5,
                    px: 0.5,
                    fontStyle: "normal",
                    fontFamily: "'Barlow Condensed', sans-serif",
                    textTransform: "uppercase",
                    color: "#F01E28",
                  }}
                >
                  {u.time.formatTimeRange(
                    session?.startedAt,
                    session?.duration
                  )}
                </UI.Typography>
              </UI.Box>
            </UI.Box>
            <UI.Box sx={{ display: "flex" }}>
              <Icons.LocationOn
                sx={{
                  verticalAlign: "middle",
                  fontSize: "40px",
                  mt: 3,
                  ml: 1.5,
                }}
              />
              <UI.Typography variant="h4" sx={{ mt: 4, ml: 2 }}>
                {session?.area?.name}
              </UI.Typography>
            </UI.Box>
            {session && session?.signups.length > 0 && (
              <UI.Box sx={{ textAlign: "left", mt: 3 }}>
                {session && (
                  <C.Attendees easySignup={true} session={session} sx={{}} />
                )}
              </UI.Box>
            )}
          </UI.Box>

          {session?.address && (
            <>
              <UI.Card sx={{ mb: 2, px: 2 }}>
                <UI.Typography variant="h4" sx={{ mt: 1, mb: 1 }}>
                  About the task
                </UI.Typography>

                <UI.Typography variant="body1" sx={{ fontSize: "16px" }}>
                  <UI.Box
                    className="gg-markdown"
                    dangerouslySetInnerHTML={{
                      __html: session.description,
                    }}
                  />
                </UI.Typography>
              </UI.Card>

              <LocationCard session={session} />

              <UI.Box>
                {session.organisation && (
                  <UI.Accordion sx={{ mb: 2 }}>
                    <UI.AccordionSummary
                      expandIcon={
                        <Icons.ExpandMore sx={{ fontSize: "24px" }} />
                      }
                      aria-controls="this-task-supports-content"
                      id="this-task-supports-header"
                    >
                      <UI.Typography variant="h4" sx={{ my: 1 }}>
                        This task supports{" "}
                        {session.organisation &&
                          `the organisation ${session.organisation.name}`}
                      </UI.Typography>
                    </UI.AccordionSummary>
                    <UI.AccordionDetails>
                      <UI.OrganisationCard
                        organisation={session.organisation}
                      />
                    </UI.AccordionDetails>
                  </UI.Accordion>
                )}
                <UI.Accordion sx={{ mb: 2 }}>
                  <UI.AccordionSummary
                    expandIcon={<Icons.ExpandMore sx={{ fontSize: "24px" }} />}
                    aria-controls="what-to-look-out-for-content"
                    id="what-to-look-out-for-header"
                  >
                    <UI.Typography variant="h4" sx={{ my: 1 }}>
                      What to look out for
                    </UI.Typography>
                  </UI.AccordionSummary>
                  <UI.AccordionDetails>
                    <C.WhatToLookOutFor
                      session={session}
                      easySignup={true}
                      sx={{ my: 3 }}
                    />
                  </UI.AccordionDetails>
                </UI.Accordion>

                {!md && (
                  <UI.Card sx={{ my: 1, display: { xs: "block", md: "none" } }}>
                    <UI.Grid container spacing={3} sx={{}}>
                      {testimonials.map((quote, index) => (
                        <UI.Grid item xs={12} md={6} key={index}>
                          <UI.Card
                            sx={{ height: { md: "480px", lg: "330px" } }}
                          >
                            <UI.Box
                              sx={{
                                borderTop: "4px solid #E11018",
                              }}
                            />
                            <UI.Box
                              sx={{
                                p: 1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <UI.Avatar
                                src={quote.image}
                                sx={{ height: "120px", width: "120px" }}
                              />
                              <UI.Box sx={{ display: "flex" }}>
                                <UI.Typography
                                  variant="h5"
                                  sx={{ mr: 1, mt: 1 }}
                                >
                                  ||
                                </UI.Typography>
                                <UI.Typography variant="body1" sx={{ mt: 2 }}>
                                  {quote.quote}
                                </UI.Typography>
                              </UI.Box>

                              <UI.Typography
                                variant="body1"
                                color={"primary.main"}
                                sx={{ mt: 2, textAlign: "right" }}
                              >
                                {quote.name}
                              </UI.Typography>
                            </UI.Box>
                          </UI.Card>
                        </UI.Grid>
                      ))}
                    </UI.Grid>
                  </UI.Card>
                )}
                <UI.Accordion sx={{ mb: 2 }}>
                  <UI.AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ fontSize: "24px" }} />}
                    aria-controls="what-to-look-out-for-content"
                    id="what-to-look-out-for-header"
                  >
                    <UI.Typography variant="h4" sx={{ my: 1 }}>
                      About GoodGym
                    </UI.Typography>
                  </UI.AccordionSummary>
                  <UI.AccordionDetails>
                    <UI.Typography variant="body1">
                      GoodGym is a community of people that get fit by doing
                      good. We run, walk and cycle to do practical tasks for
                      community organisations and isolated older people.
                    </UI.Typography>
                  </UI.AccordionDetails>
                </UI.Accordion>
              </UI.Box>
            </>
          )}
        </UI.Box>
      </UI.Box>
      <UI.Box
        sx={{
          width: { xs: "100%", md: "100%" },
          textAlign: "center",
          display: { xs: "block", md: "none" },
          position: "fixed",
          bottom: 0,
          zIndex: 999,
        }}
      >
        {hasSignedUpTrained ? (
          <UI.Box sx={{ background: "white" }}>
            <UI.Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: "primary.main",
                fontSize: "24px",
                py: 2,
              }}
            >
              Congratulations, you're going!
            </UI.Typography>
          </UI.Box>
        ) : (
          <UI.Button
            fullWidth
            onClick={
              completeTraining
                ? runSignUpToSession
                : runner && !session?.signedUp
                ? runnerSignUp
                : showSignupModal
            }
            sx={{
              fontSize: "24px",
            }}
          >
            Join this open session
          </UI.Button>
        )}
        {showComponent && session && (
          <SessionSignupDialog
            onClose={hideSignupModal}
            open={showComponent}
            session={session}
          />
        )}
      </UI.Box>
    </UI.Box>
  );
};

export default EasySignUpPage;
