import * as UI from "@goodgym/components";
import Banner from "@goodgym/components/Banner";
import {
  useCampaignPageQuery
} from "@goodgym/graphql/hooks";
import { useParams } from "react-router-dom";
import * as C from "./components";
import SessionSearch from "./components/SessionSearch";
import { useAuth } from "@goodgym/auth";

export type CampaignPageProps = UI.BoxProps & {slug?:string}

const CampaignPage: React.FC<CampaignPageProps> = (props) => {
  const params = useParams();
  const slug = params.slug!;
  const { data, error } = useCampaignPageQuery({
    variables: { slug: slug },
    skip: !(props.slug || slug),
  });

  const { runner } = useAuth()
  const canEditCampaign = runner?.roles.includes("CENTRAL_ADMIN")


  if (error || !(props.slug || slug)) return <UI.Error error={error} />;
  if (!data) return <UI.Loading />;

  const body = data?.campaignPage.body;
  const tag = data?.campaignPage.sessionTag;

  return (
    <>
      <Banner
        title={data?.campaignPage.title}
        tagline={data?.campaignPage.strapline}
        image={data?.campaignPage.imageUrl}
        logo={data?.campaignPage.logoUrl}
      />

      <C.AboutCampaign content={body} />
      <UI.Container sx={{ my: 3 }}>
       {canEditCampaign && <UI.Box sx={{textAlign:"left"}}>
        <UI.Button href={`/campaign_pages/${data.campaignPage.slug}/edit`}>
          Edit this campaign
          </UI.Button>
        </UI.Box>}
        <UI.Container sx={{ textAlign: "center", mb: "100px" }}>
          <SessionSearch tag={tag} easySignUp={true} />
        </UI.Container>
      </UI.Container>
    </>
  );
};

export default CampaignPage;
