/** @jsxImportSource @emotion/react */
import { useAuth } from "@goodgym/auth";
import * as UI from "@goodgym/components";
import { useEasySignUpMutation } from "@goodgym/graphql/hooks";
import { useSignUpToSession } from "@goodgym/graphql/mutations";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import React, { useCallback, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Training from "../Training";

export type EasySignUpProps = {
  onClose: React.MouseEventHandler;
  session: T.SessionCardFragment & {
    ledBy?: Optional<T.RunnerFragment>;
    risks: T.SessionRiskFragment[];
    taskforceRoles: T.TaskforceRoleFragment[];
    groupFitnessActivities: (T.GroupFitnessActivityFragment & {
      signups: T.RunnerFragment[];
      ledBy: T.RunnerFragment;
    })[];
  };
};

export type Response =
  | {
      __typename?: "PasswordlessSignUpMutationPayload";
      success: boolean;
      errors?: Array<string> | null | undefined;
      userId?: string | null | undefined;
      message?: string | null | undefined;
      userType?: string | null | undefined;
      token?: string | null | undefined;
      redirectPath?: string | null | undefined;
    }
  | null
  | undefined;

const EasySignUp: React.FC<EasySignUpProps> = ({ session, onClose }) => {
  const params = useParams();
  const campaignTag = params.campaign || null
  const slug = params.slug!;
  const [param] = useSearchParams();
  const { runner } = useAuth();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState<React.ReactNode | null>(
    null
  );
  const [response, setResponse] = useState<Response>(null);
  const [page1, setPage1] = useState<Boolean>(false);
  const [hasAlreadySignedUp, setHasAlreadySignedUp] = useState<Boolean>(
    session.signedUp
  );
  const navigate = useNavigate();
  //If the runner is already logged in, check if it has completed the training
  const hasCompletedTraining =
    runner &&
    (u.training.completed(runner, "GROUP_SESSION") ||
      u.training.passed(runner, "GROUP_SESSION"));

  //taskforceRoles to be sent to sign up for session
  const taskforceRoles = session.taskforceRoles.filter((r) =>
    param.getAll("tf-role-id").includes(r.id)
  );

  //creating a user mutation
  const [easySignUp, { loading }] = useEasySignUpMutation({
    onCompleted: (data) => {
      if (data.passwordlessSignUp?.success) {
        setSubmitted(true);
      } else {
        setErrorMessage(
          data.passwordlessSignUp?.errors?.join(", ") || "An error occurred."
        );
      }
    },
    onError: (err) => {
      setErrorMessage(err.message);
    },
  });

  React.useEffect(() => {
    setSubmitted(submitted);
    setHasAlreadySignedUp(hasAlreadySignedUp);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted]);

  //Signing up for a session once you have either logged in or created a user account
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const signUpToSession = useCallback(
    useSignUpToSession({
      session,
      taskforceRoles,
      easySignUp: true,
    }),
    [session, taskforceRoles]
  );

  const runSignUpToSession = async () => {
    await signUpToSession();
    setHasAlreadySignedUp(true);
    navigate(`/v3/signed-up/${slug}/?name=${name}`);
  };

  //Once you click the submit button
  const handleSubmit = async () => {
    if (!email || !name) {
      setErrorMessage("Name and email are required.");
      return;
    }
console.log("campaignTAg", campaignTag)
    try {
      const { data } = await easySignUp({
        variables: {
          email,
          name,
          areaId: session?.area?.id || undefined,
          signUpSlug: slug,
          tag: campaignTag
        },
      });

      if (data?.passwordlessSignUp?.success) {
        return data.passwordlessSignUp;
      } else {
        setErrorMessage(
          data?.passwordlessSignUp?.errors?.join(", ") ||
            "Something went wrong."
        );
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const handleUserFlow = async () => {
    const response = await handleSubmit();
    setPage1(true);
    setResponse(response);
    if (!response) return;

    if (response.userType === "existing") {
      setErrorMessage(
        <>
          An account with this email already exists. Try logging in.
          <UI.Typography sx={{ textAlign: "center" }}>
            <UI.SignInButton variant="text">Sign in</UI.SignInButton>
          </UI.Typography>
        </>
      );

      return;
    }

    if (response.userType === "new" && response.success) {
      await signUpToSession();
      console.log("i'm signed up", session.signedUp);
      setHasAlreadySignedUp(true);
    } else {
      setErrorMessage(response.errors?.join(", ") || "Something went wrong.");
    }
  };

  const handleTrainingClick = () => {
    setPage1(false);
    console.log("i'm signed wwup", session.signedUp);
  };

  console.log({
    hasCompletedTraining: hasCompletedTraining,
    hasAlreadySignedUp: hasAlreadySignedUp,
    runner: runner?.name,
    submitted: submitted,
    page1: page1,
  });

  return (
    <UI.DialogContent sx={{ width: "auto" }}>
      <UI.DialogTitle sx={{ textAlign: "center", fontSize: "22px" }}>
        {(!runner && !hasAlreadySignedUp) || (runner && !hasAlreadySignedUp)
          ? `Join session: ${session.title}!`
          : hasCompletedTraining && hasAlreadySignedUp
          ? "You've signed up!"
          : hasAlreadySignedUp && !hasCompletedTraining && !submitted
          ? `Complete the training`
          : page1 &&
            ` Signup confirmation:
          You've registered for: ${session.title}!`}
        <UI.CloseButton onClick={onClose} />
      </UI.DialogTitle>
      {!hasCompletedTraining && !submitted && !runner && !page1 ? (
        <>
          <UI.Typography variant="body1" sx={{ my: 2 }}>
            No experience required. Just let us know you're coming, turn up, and
            get involved!
          </UI.Typography>

          <UI.Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <UI.TextField
              color="primary"
              label="Name"
              type="text"
              autoComplete="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <UI.TextField
              color="primary"
              label="Email"
              type="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <UI.Typography variant="body1">
              By signing up, you acknowledge that you have read and understood
              our{" "}
              <Link to="/terms" target="_blank" rel="noopener noreferrer">
                Terms & Conditions
              </Link>
            </UI.Typography>

            <UI.DialogActions sx={{ m: 1 }}>
              <UI.Button fullWidth onClick={handleUserFlow} disabled={loading}>
                {loading ? "Signing Up..." : "Join Session"}
              </UI.Button>
            </UI.DialogActions>
          </UI.Box>
        </>
      ) : errorMessage && submitted ? (
        <UI.Alert severity="error">{errorMessage}</UI.Alert>
      ) : (
        hasCompletedTraining &&
        !hasAlreadySignedUp && (
          <>
            <UI.Typography variant="body1" sx={{ my: 2, textAlign: "center" }}>
              You've completed the training already. <p />
              <UI.Link onClick={runSignUpToSession}>Click here </UI.Link>
              to sign up!
            </UI.Typography>
          </>
        )
      )}
      {(runner || response?.userType === "new") &&
        !hasCompletedTraining &&
        hasAlreadySignedUp &&
        !page1 && (
          <Training
            aria-label="groupSession"
            slug="group-session-safety-briefing"
            onClose={onClose}
            easySignup={true}
            name={name}
            URLslug={slug}
          />
        )}
      {submitted && page1 && hasAlreadySignedUp && (
        <>
          <UI.Typography>
            We'll see you on{" "}
            <b>
              <u>{u.time.format(session?.startedAt, "EEEE do MMMM yyyy")}</u>
            </b>{" "}
            at {u.time.formatTime(session?.startedAt)}
          </UI.Typography>
          <UI.Typography variant="body1" sx={{ my: 2 }}>
            We’re looking forward to seeing you.Makes sure it's in your diary.
            {" "}
            <p /> No experience is necessary, lots of new people come to GoodGym
            sessions every week. Just turn up ready to be active. <p />
            Ensure you complete your safety briefing before attending.
          </UI.Typography>{" "}
          <UI.Button fullWidth onClick={handleTrainingClick}>
            Complete briefing now
          </UI.Button>
        </>
      )}
    </UI.DialogContent>
  );
};

export default EasySignUp;
