import * as T from "@goodgym/graphql/types";

export const trainings = {
  COMMUNITY_SESSIONS: "group-session-training",
  GROUP_SESSION: "group-session-safety-briefing",
  OLDER_PEOPLE_SESSIONS: "missions-training",
  RUN_LEADER_TRAINING: "run-leader-training-2021",
  TASKFORCE_TRAINING: "taskforce-training-2021",
};

export const hasStatus =
  (status: T.TrainingStatus) =>
  (
    runner: T.CurrentRunnerTrainingStatusFragment,
    training: keyof typeof trainings
  ) => {
    return !!runner.trainingStatuses.find(
      (ts) => ts.slug === trainings[training] && ts.status === status
    );
  };

export const passed = hasStatus("PASSED");
export const failed = hasStatus("FAILED");
export const completed = hasStatus("COMPLETED");
export const incomplete = hasStatus("INCOMPLETE");
