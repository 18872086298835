/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

export type PostcodeSearchProps = UI.BoxProps & {
  fullWidth?: boolean;
  missionPage?: boolean;
  groupRunPage?: boolean;
  communityMissionPage?: boolean;
  socialVisitPage?: boolean;
};

const PostcodeSearch: React.FC<PostcodeSearchProps> = ({
  fullWidth = false,
  communityMissionPage,
  missionPage,
  groupRunPage,

  socialVisitPage,
  ...props
}) => {
  const navigate = useNavigate();
  const [postcode, setPostcode] = React.useState("");


  const handleOnClick = () => {
    const code = u.format.postcode(postcode);
    if (communityMissionPage) {
      navigate(`/v3/sessions?type=CommunityMission&postcode=${code}`);
    }
    if (groupRunPage) {
      navigate(`/v3/sessions?type=GroupRun&postcode=${code}`);
    }
      if (missionPage) {
        navigate(`/v3/sessions?type=Mission&postcode=${code}`);
      }
      if (socialVisitPage) {
        navigate(`/v3/social-visits?postcode=${code}`);
      }
  };

  return (
    <UI.Box {...props}>
      {missionPage || groupRunPage || communityMissionPage ? (
        <UI.TextField
          value={postcode}
          onChange={(e) => setPostcode(e.target.value?.toUpperCase())}
          fullWidth={fullWidth}
          sx={{ mb: 3 }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleOnClick();
            }
          }}
          InputProps={{
            endAdornment: (
              <UI.InputAdornment position="end">
                <UI.IconButton
                  edge="end"
                  color="primary"
                  type="submit"
                  aria-label="Search"
                  onClick={handleOnClick}
                >
                  <Icons.ArrowForward />
                </UI.IconButton>
              </UI.InputAdornment>
            ),
          }}
        />
      ) : (
        <>
          <UI.Typography
            variant="h3"
            color="neutral.light"
            sx={{
              textTransform: "uppercase",
              mt: 3,
              mb: 3,
            }}
          >
            Find a social visit near you
          </UI.Typography>

          <UI.TextField
            name="postcode"
            placeholder="Enter your postcode"
            variant="outlined"
            onChange={(e) => setPostcode(e.target.value?.toUpperCase())}
            value={postcode}
            fullWidth={fullWidth}
            sx={{ mb: 3 }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleOnClick();
              }
            }}
            InputProps={{
              endAdornment: (
                <UI.InputAdornment position="end">
                  <UI.IconButton
                    edge="end"
                    color="primary"
                    type="submit"
                    aria-label="Search for sessions"
                    onClick={handleOnClick}
                  >
                    <Icons.ArrowForward />
                  </UI.IconButton>
                </UI.InputAdornment>
              ),
            }}
          />
        </>
      )}
    </UI.Box>
  );
};

export default PostcodeSearch;
