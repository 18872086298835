/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAuth } from "@goodgym/auth";

type DefaultLayoutProps = {
  clientOnly?: boolean;
  easySignUp?: boolean;
};

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ clientOnly, easySignUp }) => {
  const { pathname } = useLocation();
  const { ready } = useAuth();
  return (
    <>
      {!easySignUp && <UI.Navbar />}
      <UI.BreakpointIndicator />
      <UI.Box
        sx={{
          minHeight: { xs: "calc(100vh - 100px)", sm: "calc(100vh - 64px)" },
          overflow: "hidden",
          backgroundColor: "neutral.light",
        }}
      >
        {(!clientOnly || ready) && <Outlet />}
      </UI.Box>

     {!easySignUp && <UI.Box sx={{ display: { xs: "block", sm: "none" }, zIndex: 2 }}>
        <UI.Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 2 }}
          elevation={3}
        >
          <UI.BottomNavigation showLabels value={pathname}>
            <UI.BottomNavigationAction
              label="Home"
              icon={<Icons.Home sx={{ height: "24px", width: "24px" }} />}
              value={u.links.home()}
              component={UI.Link}
              to={u.links.home()}
            />
            <UI.BottomNavigationAction
              label="Sessions"
              icon={<Icons.Today sx={{ height: "24px", width: "24px" }} />}
              value={u.links.sessions()}
              component={UI.Link}
              href="/v3/sessions"
            />
            <UI.BottomNavigationAction
              label="More"
              icon={<Icons.Menu sx={{ height: "24px", width: "24px" }} />}
              value={u.links.more()}
              component={UI.Link}
              to={u.links.more()}
            />
          </UI.BottomNavigation>
        </UI.Paper>
      </UI.Box>}
      <UI.Box sx={{ display: { xs: "none", md: "block" } }}>
        <UI.Footer />
      </UI.Box>
    </>
  );
};

export default DefaultLayout;
