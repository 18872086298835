/** @jsxImportSource @emotion/react */
import React from "react";
import { useCreateCommentMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";


export type CreateCommentMutationHook = (params: {
  runner: Optional<T.CurrentRunnerFragment>;
  commentable: T.CommentableItem;
  content: string;
  setContent: (content: string) => void;
}) => (e: React.KeyboardEvent | React.MouseEvent) => void;

export const useCreateComment: CreateCommentMutationHook = ({
  runner,
  commentable,
  content,
  setContent,
}) => {
  const [contentInFlight, setContentInFlight] = React.useState("");

  const [createComment] = useCreateCommentMutation({
    variables: {
      commentableId: commentable.id,
      commentableType: u.comments.commentableType(commentable)!,
      content: content.trim(),
    },
    onCompleted: () => {
      setContentInFlight("");
    },
    onError: () => {
      setContent(contentInFlight);
      setContentInFlight("");
    },
    optimisticResponse: {
      response: {
        __typename: "CreateCommentMutationPayload",
        commentable: {
          __typename: commentable.__typename,
          id: commentable.id,
          comments: [
            ...commentable.comments,
            {
              __typename: "Comment",
              id: `tmp-comment-${commentable.id}`,
              content: content,
              runner: { ...runner!, __typename: "Runner" },
              createdAt: new Date().toISOString(),
            },
          ],
        },
      },
    },
  });
  return (e) => {
    e?.preventDefault();
    if (content.trim() !== "") {
      setContentInFlight(content);
      setContent("");
      createComment();
    }
  };
};
