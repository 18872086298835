/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useDismissCta } from "@goodgym/graphql/mutations";
import StarsIcon from "@mui/icons-material/Stars";

export type CtaProps = UI.CardProps & {
  cta: T.CtaFragment;
  runnerRoles?: string[];
};

const Cta: React.FC<CtaProps> = ({ cta,...props }) => {
  const action = () => u.analytics.track("cta_actioned", { cta_id: cta.id });
  const dismiss = useDismissCta({ cta });

  return (
    <>
      <UI.Card
        {...props}
        sx={{
          background: cta.id === "join_taskforce" ? "#E1E1E1" : "#FFFFF",
          mb: 3,
        }}
      >
        <UI.CardHeader
          title={<UI.Typography variant="subtitle1">{cta.title}</UI.Typography>}
          avatar={
            cta.id !== "join_taskforce" ? (
              <UI.Box
                component="img"
                src={cta.icon}
                sx={{
                  width: "40px",
                }}
                alt={cta.description}
              />
            ) : (
              <StarsIcon sx={{ fontSize: "38px", color: "orange" }} />
            )
          }
        />
        <UI.CardContent sx={{ py: 0 }}>
            <UI.Typography variant="body1" dangerouslySetInnerHTML={{ __html: cta.description }} ></UI.Typography>
        </UI.CardContent>
        <UI.CardActions>
          <UI.Button
            href={cta.url}
            color="primary"
            variant="text"
            onClick={action}
          >
            {cta.actionText}
          </UI.Button>
          <UI.Button variant="text" onClick={dismiss} color="neutral">
            No thanks
          </UI.Button>
        </UI.CardActions>
      </UI.Card>
    </>
  );
}

export default Cta;
