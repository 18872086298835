import React, { useState, useEffect } from "react";
import * as UI from "@goodgym/components";

export type CountProps = {
  number: number;
}

const Counter: React.FC<CountProps> = ({ number }) => {
  const initialNumber= (number < 10 ) ? number : (number > 100) ? number -20 : number -10
  
  const [count, setCount] = useState(initialNumber);
    const [isVisible, setIsVisible] = React.useState(false);
    const containerRef = React.useRef(null);

  const target = number; 
  const intervalDuration = 100; 

    useEffect(() => {
      if (isVisible)
        if (count < target) {
          const interval = setInterval(() => {
            setCount((prevCount) => {
              const nextCount = prevCount + 1;
              if (nextCount >= target) {
                clearInterval(interval);
                return target;
              }
              return nextCount;
            });
          }, intervalDuration);

          return () => clearInterval(interval);
        }
    }, [count, target, isVisible]);

  React.useEffect(() => {
    const currentRef = containerRef.current; 
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);



  return (
    <UI.Typography ref={containerRef} variant="h1" sx={{ textAlign: "center" }}>
      <span style={{ color: "red" }}>{count}</span>
    </UI.Typography>
  );
};

export default Counter;
