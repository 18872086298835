/** @jsxImportSource @emotion/react */
import { useAuth } from "@goodgym/auth";
import * as UI from "@goodgym/components";
import { useSessionPageQuery } from "@goodgym/graphql/hooks";
import * as Icons from "@goodgym/icons";
import * as u from "@goodgym/util";
import React from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import * as C from "./components";

/*
 * we allow passing in of a slug here in case the page is being rendered
 * as a panel in the sessions listing page. if so, the passed in slug
 * takes precedence over any slug in the url (there should never be
 * a slug in the url if this is the case)
 */
export type SessionPageProps = UI.BoxProps & { panel?: boolean; slug?: string };

const SessionPage: React.FC<SessionPageProps> = (props) => {
  const { runner } = useAuth();
  const { slug } = useParams();
  const { error, data } = useSessionPageQuery({
    variables: { slug: props.slug || slug! },
    skip: !(props.slug || slug),
  });

  const commentsRef = React.useRef<HTMLSpanElement | null>(null);
  const scrollToComments = () => {
    commentsRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const [params, setParams] = useSearchParams();
  const isSigningUp = !!params.get("session-signup");
  const showSignupModal = () => setParams({ "session-signup": "true" });
  const hideSignupModal = () => setParams({});

  /*
   * If there's a report we should redirect to the report page.
   * Once the new React report page is out this should be removed
   * and a check done further down to make the component do
   * return <Navigate to={u.links.report(session.report)} />;
   * if there's a report. This will avoid a full page refresh
   * by using react-router instead.
   */
  React.useEffect(() => {
    if (!!data?.session.report && !!data.session.report?.publishedAt) {
      window.location.href = u.links.report(data.session.report);
    }
  }, [data?.session]);


  if (error || !(props.slug || slug)) return <UI.Error error={error} />;
  if (!data) return <UI.Loading />;

  const { session } = data;
  const { area } = session;
  const involvesOrganisations = [
    "Mission",
    "CommunityMission",
    "GroupRun",
  ].includes(session.type);

  if (!!session.report) return <Navigate to={u.links.report(session.report)} />;

  return (
    <>
      <UI.Seo
        title={`${session.title} - GoodGym ${area?.name}`}
        description={session.strapline}
        image={session.imageUrl}
        url={u.links.session(session)}
      />

      <UI.SessionSignupDialog
        open={isSigningUp}
        onClose={hideSignupModal}
        session={session}
      />

      <UI.Box
        sx={{
          pb: { xs: "120px", sm: 0 },
          minHeight: "100vh",
          overflowX: "hidden",
          ...props.sx,
        }}
      >
        <UI.Box sx={{ backgroundColor: "neutral.light" }}>
          <UI.Container>
            <UI.Box
              sx={{
                flexDirection: "column",
                pt: 2,
                mb: 2,
              }}
            >
              {!props.panel && (
                <UI.Breadcrumbs
                  crumbs={[
                    ["Home", u.links.home()],
                    ["Sessions", u.links.sessions()],
                    [session.title, u.links.session(session)],
                  ]}
                />
              )}
              <C.Header
                session={session}
                runner={runner}
                sx={{ mt: 3, display: { xs: "block", md: "none" } }}
              />
            </UI.Box>
            <UI.Grid columnSpacing={5} container sx={{ alignItems: "stretch" }}>
              <UI.Grid
                item
                xs={12}
                sm={6}
                lg={8}
                sx={{ alignSelf: "flex-start", order: { xs: 1, sm: 2 } }}
              >
                <UI.ImageCarousel
                  images={session.imageUrls.map((src) => ({ src }))}
                  sx={{ mx: { xs: -2, sm: 0 }, mb: 2, mt: { xs: 0, sm: 3 } }}
                />
              </UI.Grid>
              <UI.Grid
                item
                xs={12}
                sm={6}
                lg={4}
                sx={{ order: { xs: 2, sm: 1 } }}
              >
                <C.Header
                  session={session}
                  runner={runner}
                  sx={{ display: { xs: "none", md: "block" }, mt: 2, mb: 2 }}
                />
                <UI.Box
                  sx={{
                    display: "flex",
                    mb: 2,
                    flexDirection: "column",
                  }}
                >
                  <UI.Box sx={{ display: "flex" }}>
                    <UI.AreaChip area={area} sx={{ mr: 1 }} />
                    <UI.SessionTypeChip session={session} sx={{ mr: 1 }} />
                    <C.SignupStatus session={session} />

                  </UI.Box>
                  <C.SecondaryActions session={session} sx={{ mt: 1 }} />
                </UI.Box>
                <UI.AvatarGroup
                  max={8}
                  sx={{
                    justifyContent: "left",
                    ml: "-2px",
                    mb: 2,
                    "& .MuiAvatar-root": {
                      width: u.avatarSize("small"),
                      height: u.avatarSize("small"),
                      fontSize: "12px",
                      borderColor: "neutral.light",
                    },
                  }}
                >
                  {session.signups.map((r) => (
                    <UI.RunnerAvatar
                      key={r.id}
                      runner={r}
                      size="small"
                      noStatus
                    />
                  ))}
                </UI.AvatarGroup>
                <UI.Box
                  sx={{
                    mb: 3,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <UI.Box sx={{ flexGrow: 1, flexShrink: 0 }}>
                    <UI.Address address={session.address} />
                    <C.DateTime session={session} />
                    <C.ShareButton session={session} />
                  </UI.Box>
                  <UI.Box
                    sx={{ display: { xs: "none", sm: "block" }, flexShrink: 0 }}
                  >
                    <C.SignupButton
                      session={session}
                      fullWidth
                      onClick={showSignupModal}
                    />
                    {session.signedUp && (
                    <UI.Typography sx={{ mt: 2 }}>
                       <Icons.NewReleases
                                  sx={{ verticalAlign: "text-middle", fontSize: "22px", color: "primary.main"}}
                                />  New! Easy 2-step sign-up process –  <UI.Link
                    href={`/v3/sign-up/${slug}`}>bring a friend!
                    </UI.Link>
                    </UI.Typography>
                    )}
                  </UI.Box>

                </UI.Box>
              </UI.Grid>
            </UI.Grid>
          </UI.Container>
        </UI.Box>
        <UI.Box sx={{ backgroundColor: "white.main" }}>
          <UI.Container>
            <UI.Grid columnSpacing={5} container sx={{ pt: 3, mb: 2 }}>
              <UI.Grid xs={12} md={6} lg={8} item>
                <UI.Box
                  sx={{
                    flexShrink: 0,
                  }}
                >
                  <UI.Box>
                    <C.GettingThere session={session} />
                  </UI.Box>
                </UI.Box>
                <UI.Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
                  About this session
                </UI.Typography>
                <UI.Box
                  className="gg-markdown"
                  dangerouslySetInnerHTML={{ __html: session.description }}
                />
              </UI.Grid>
              <UI.Grid xs={12} md={6} lg={4} item sx={{ mt: { xs: 3, md: 0 } }}>
                <UI.Box
                  sx={{
                    mt: 2,
                    pl: { xs: 0, md: 3 },
                    borderLeft: { xs: "none", md: "1px solid" },
                    borderColor: { md: "#dadada" },
                  }}
                >
                  {!session.organisation &&
                    involvesOrganisations &&
                    u.roles.hasSessionAdmin(runner, session) && (
                      <UI.Box sx={{ backgroundColor: "warning.light", p: 2 }}>
                        <UI.Typography variant="h5" sx={{ mb: 2 }}>
                          <Icons.WarningAmber
                            sx={{ mt: "-4px", verticalAlign: "middle" }}
                          />{" "}
                          Missing organisation
                        </UI.Typography>
                        <UI.Box>
                          The organisation is not set on this listing. Please{" "}
                          <UI.Link href={u.links.editSession(session)}>
                            edit the session
                          </UI.Link>{" "}
                          and add organisation details. These details will then
                          be pulled through to this page to help people see who
                          they will be supporting when they take part.
                        </UI.Box>
                      </UI.Box>
                    )}

                  {session.organisation && (
                    <UI.Box sx={{ mt: 4, mb: 3 }}>
                      <UI.Typography variant="h5" sx={{ my: 2 }}>
                        This task supports
                      </UI.Typography>
                      <UI.OrganisationCard
                        organisation={session.organisation}
                      />
                    </UI.Box>
                  )}
                  <C.WhatToLookOutFor session={session} sx={{ my: 3 }} />
                </UI.Box>
              </UI.Grid>
            </UI.Grid>
            <UI.Box sx={{ display: { xs: "none", sm: "block" }, mb: 3 }}>
              <C.SignupButton session={session} onClick={showSignupModal} />
            </UI.Box>

            <UI.Divider sx={{ mx: { xs: -2, sm: 0 } }} />
            <UI.Grid columnSpacing={5} container>
              <UI.Grid item xs={12} md={6} lg={8}>
                <C.Attendees session={session} sx={{ my: 3 }} />
              </UI.Grid>
              <UI.Grid item xs={12} md={6} lg={4}>
                <C.Help
                  session={session}
                  askQuestion={scrollToComments}
                  sx={{ my: 3 }}
                />
              </UI.Grid>
            </UI.Grid>
            {session.address && (
              <>
                <UI.Divider sx={{ mx: { xs: -2, sm: 0 } }} />
                <UI.Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
                  Location
                </UI.Typography>
                <UI.Grid container sx={{ mb: 3 }}>
                  <UI.Grid item xs={12} sm={6} lg={4}>
                    <UI.Address address={session.address} noIcon />
                  </UI.Grid>
                  <UI.Grid item xs={12} sm={6} lg={8}>
                    <UI.Typography
                      variant="body2"
                      sx={{ whiteSpace: "pre-wrap" }}
                    >
                      {session.address.otherInformation}
                    </UI.Typography>
                    <UI.Typography
                      variant="body2"
                      sx={{ whiteSpace: "pre-wrap" }}
                    >
                      {session.address.accessNotes}
                    </UI.Typography>
                  </UI.Grid>
                </UI.Grid>
                <UI.Box sx={{ mx: { xs: -2, sm: 0 } }}>
                  <C.Map session={session} />
                </UI.Box>
              </>
            )}
            <UI.Divider sx={{ mx: { xs: -2, sm: 0 } }} />
            <UI.Typography ref={commentsRef} variant="h5" sx={{ mt: 3, mb: 1 }}>
              Comments and questions
            </UI.Typography>
            <UI.Grid columnSpacing={5} container sx={{ mb: 3 }}>
              <UI.Grid
                item
                xs={12}
                md={8}
                lg={6}
                sx={{ order: { xs: 2, md: 1 } }}
              >
                <UI.Comments commentable={session} />
              </UI.Grid>
              <UI.Grid
                item
                xs={12}
                md={4}
                lg={6}
                sx={{ mt: 1, order: { xs: 1, md: 2 } }}
              >
                <UI.Typography variant="body2" sx={{ mb: 2 }}>
                  Comments are a good place to ask any questions you may have
                  about the session.
                </UI.Typography>

                <UI.Typography variant="body2" sx={{ mb: 2 }}>
                  Please note that comments written here are publicly visible:
                  avoid posting private information such as your mobile phone
                  number.
                </UI.Typography>

                <UI.Typography variant="body2" sx={{ mb: 2 }}>
                  If you have a question you would like to ask privately, you
                  can{" "}
                  <UI.Link
                    href={`mailto:${area?.email || "getinvolved@goodgym.org"}`}
                  >
                    email
                  </UI.Link>{" "}
                  {area?.areaActivator ? (
                    <>
                      the GoodGym {area.name} area activator,{" "}
                      <UI.RunnerLink runner={area.areaActivator} />,
                    </>
                  ) : (
                    "the GoodGym central team"
                  )}{" "}
                  .
                </UI.Typography>
              </UI.Grid>
            </UI.Grid>
          </UI.Container>
        </UI.Box>
        <UI.Box
          sx={{
            position: "fixed",
            backgroundColor: "white.main",
            left: 0,
            right: 0,
            p: 2,
            bottom: props.panel ? 0 : { xs: "56px", sm: 0 },
            borderTop: "1px solid",
            borderBottom: "1px solid",
            borderColor: "outline.main",
            zIndex: 2,
            overflow: "hidden",
            display: { xs: "block", sm: "none" },
          }}
        >
          <C.SignupButton session={session} onClick={showSignupModal} />
        </UI.Box>
      </UI.Box>
    </>
  );
};

export default SessionPage;
