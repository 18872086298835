/** @jsxImportSource @emotion/react */
import { useAuth } from "@goodgym/auth";
import * as UI from "@goodgym/components";
import { useSessionsFeed } from "@goodgym/components/SessionsFeed/useSessionFeed";
import { useSocialVisits } from "@goodgym/pages/SocialVisitsPage/components/hooks";
import * as u from "@goodgym/util";
import HouseIcon from "@mui/icons-material/House";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as C from "./components";
import ShowSocialVisitsLink from "./components/Header/ShowSocialVisitsLink";

export type DrawerView = "FILTER_VIEW" | "CALENDAR_VIEW";

const SessionsPage = () => {
  const [drawerView, setDrawerView] = useState<DrawerView>("FILTER_VIEW");
  const location = useLocation();
  const navigate = useNavigate();

  const [hasRendered, setHasRendered] = useState(false);

  const { runner } = useAuth();
  const theme = UI.useTheme();
  const md = UI.useMediaQuery(theme.breakpoints.up("md"));

  const state = useSessionsFeed();

  useEffect(() => {
    if (!hasRendered && location.search) {
      setHasRendered(true);
    }
  }, [hasRendered, location, navigate]);

  const socialVisits = useSocialVisits();

  const showLinkToSocialVisit =
    !runner ||
    !runner?.homeArea?.socialVisits ||
    socialVisits?.items?.length < 0;

  const { error } = state;

  if (error) return <UI.Error error={error} />;

  return (
    <>
      <UI.Seo title="Sessions" url="/v3/sessions" />
      <C.Header state={state} setDrawerView={setDrawerView} />
      <C.Drawer state={state} drawerView={drawerView} />

      <UI.Container
        sx={{
          pt: { xs: "125px", sm: "182px", md: "112px" },
          pb: { xs: 5, sm: 0 },
        }}
      >
        <UI.Grid container spacing={3}>
          <UI.Grid item xs={12} md={8} sx={{ mt: { xs: 2, md: 10 }, mb: 2 }}>
            <>
              <UI.SessionsFeed state={state} />
            </>
          </UI.Grid>

          {md && (
            <UI.Grid item md={4} sx={{ mt: { xs: 2, md: 11 }, mb: 2 }}>
              <UI.Box sx={{ mt: 3 }}>
                {runner?.homeArea?.socialVisits && <ShowSocialVisitsLink />}
              </UI.Box>
              <C.FilterPanel state={state} />
              {showLinkToSocialVisit && (
                <UI.Box>
                  <UI.Typography
                    variant="subtitle1"
                    color="black"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <HouseIcon fontSize="large" sx={{ mr: 0.5 }} />
                    <UI.Link href={u.links.socialVisits()}>
                      Find out about social visits
                    </UI.Link>
                  </UI.Typography>
                </UI.Box>
              )}
            </UI.Grid>
          )}
        </UI.Grid>
      </UI.Container>
    </>
  );
};

export default SessionsPage;
