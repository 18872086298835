import * as UI from "@goodgym/components";

export type AboutCampaignPageProps = {
  content?: string | undefined;
}
const AboutCampaignPage:React.FC<AboutCampaignPageProps> = ({content}) => {

  return (
    <>
      <UI.Container sx={{my:3}}>
      <UI.Box
        sx={{ img: { maxWidth: "100%" } }}
        className="gg-markdown"
        dangerouslySetInnerHTML={{ __html: content ? content : ""  }}
        />
      </UI.Container>
    </>
  );
};

export default AboutCampaignPage;
