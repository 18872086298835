import powerTheGood from "./PowerTheGood.png";
import newFitnessGoal from "./NewFitnessGoal.png";
import taskOwner from "./TaskOwner.png";

const images = {
  powerTheGood,
  newFitnessGoal,
  taskOwner,
};

export default images;
