import confetti from "canvas-confetti";
import * as UI from "@goodgym/components";

const ConfettiEmoji = () => {
  const handleClick = () => {
    const scalar = 4;
    const party = confetti.shapeFromText({ text: "🎉", scalar });

    const defaults = {
      spread: 360,
      ticks: 60,
      gravity: 0,
      decay: 0.96,
      startVelocity: 20,
      shapes: [party],
      scalar,
    };

    const shoot = () => {
      confetti({
        ...defaults,
        particleCount: 30,
      });

      confetti({
        ...defaults,
        particleCount: 5,
      });

      confetti({
        ...defaults,
        particleCount: 15,
        scalar: scalar / 2,
        shapes: ["circle"],
      });
    };

    setTimeout(shoot, 0);
    setTimeout(shoot, 100);
    setTimeout(shoot, 200);
  };

  return (
    <div className="relative justify-center">
      <UI.Button onClick={handleClick}>Trigger Emoji</UI.Button>
    </div>
  );
};

export default ConfettiEmoji;

export const confettiEmoji = () => {
  const scalar = 3;
  const party = confetti.shapeFromText({ text: "🎉", scalar });

  const defaults = {
    spread: 360,
    ticks: 160,
    gravity: 0,
    decay: 0.96,
    startVelocity: 20,
    shapes: [party],
    scalar,
  };

  const shoot = () => {
    confetti({
      ...defaults,
      particleCount: 30,
    });

    confetti({
      ...defaults,
      particleCount: 5,
    });

    confetti({
      ...defaults,
      particleCount: 15,
      scalar: scalar / 2,
      shapes: ["circle"],
    });
  };

  setTimeout(shoot, 0);
  setTimeout(shoot, 100);
  setTimeout(shoot, 200);
};

export const confettiFirework = () => {
  const duration = 4 * 1000;
  const animationEnd = Date.now() + duration;
  const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

  const randomInRange = (min: number, max: number) =>
    Math.random() * (max - min) + min;

  const interval = window.setInterval(() => {
    const timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return clearInterval(interval);
    }

    const particleCount = 50 * (timeLeft / duration);
    const scalar = 2;

    const colors = ["#B20000", "#333"];
    confetti({
      ...defaults,
      particleCount,
      colors,
      scalar,

      origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
    });
    confetti({
      ...defaults,
      particleCount,
      colors,
      scalar,
      origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
    });
  }, 250);
};
