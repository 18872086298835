/** @jsxImportSource @emotion/react */
import { useSignUpToSessionMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";

export type SignUpToSessionMutationHook = (params: {
  session: T.SessionCardFragment;
  easySignUp?: boolean;
  name?: string;
  gfa?: Optional<
    T.GroupFitnessActivityFragment & {
      ledBy: T.RunnerFragment;
      signups: T.RunnerFragment[];
    }
  >;
  taskforceRoles: T.TaskforceRoleFragment[];
}) => () => Promise<any>;

export const useSignUpToSession: SignUpToSessionMutationHook = ({
  session,
  gfa,
  taskforceRoles,
  name,
  easySignUp,
}) => {
  const [signUpToSession] = useSignUpToSessionMutation({
    onError: console.log,
    onCompleted: () => { 
      if(!easySignUp)
        window.location.href = u.links.confirmSessionRegistration(
          session,
          name,
        );
    },
    variables: {
      sessionId: session.id,
      groupFitnessActivityId: gfa?.id,
      taskforceRoleIds: taskforceRoles?.map((r) => r.id) || null,
    },
    optimisticResponse: {
      response: {
        __typename: "SignUpToSessionMutationPayload",
        session: { ...session, signedUp: true },
      },
    },
  });
  return async () => await signUpToSession();
};
