/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import {
  useAreaSearchQuery,
  useSessionsFeedLazyQuery,
} from "@goodgym/graphql/hooks";
import _ from "lodash";
import * as u from "@goodgym/util";
import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useTheme } from "@goodgym/components";

export type SessionSearchProps = UI.BoxProps & {
  fullWidth?: boolean;
  socialVisit?: boolean;
  tag?: string | null;
  easySignUp?: boolean;
};

const SessionSearch: React.FC<SessionSearchProps> = ({
  tag,
  easySignUp,
  ...props
}) => {
  const theme = useTheme();
  const md = UI.useMediaQuery(theme.breakpoints.up("md"));
  const [postcode, setPostcode] = React.useState(null);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [pendingFetch, setPendingFetch] = React.useState(false);
  const [lastResultsCount, setLastResultsCount] = React.useState(0);
  const [areaId, setAreaId] = React.useState("all");

  const defaultFilters = {
    from: new Date(),
    types: ["GroupRun", "CommunityMission"],
    areaIds: [],
    postcode: postcode,
    maxDistance: 10,
    tag: tag || null,
    limit: 10,
  };

  type FiltersState = {
    from: Date;
    types: string[];
    areaIds: string[];
    postcode: string | null;
    maxDistance: number;
    tag: string | null;
    limit: number;
  };

  const [filters, setFilters] = React.useState<FiltersState>(defaultFilters);
  const [fetchedSessions, setFetchedSessions] = React.useState<any[]>([]);



  const [fetchSessions, { error: errors, data: sessions }] =
    useSessionsFeedLazyQuery({
      fetchPolicy: "cache-and-network",
    });

  const more = sessions?.sessions.more;


  React.useEffect(() => {
    const newResults = sessions?.sessions.results || [];

    if (newResults.length > 0) {
      setFetchedSessions((prev) =>
        _.uniqBy([...prev, ...newResults], "id")
      );
    }
  }, [sessions?.sessions.results]);



  React.useEffect(() => {
    if (pendingFetch && fetchedSessions.length > lastResultsCount) {
      setLoadingMore(false);
      setPendingFetch(false);
    }
  }, [fetchedSessions.length, pendingFetch, lastResultsCount]);



  const fetchMore = async () => {
    if (more && !loadingMore) {
      setLoadingMore(true);
      await fetchSessions({
        variables: {
          ...filters,
          sessionsCursorDate: _.last(fetchedSessions)?.startedAt,
          sessionsCursorId: _.last(fetchedSessions)?.id,
        },
      });
      setLoadingMore(false);
    }
  };

  const handleAreaClick = () => {
    setPostcode(null);
  };


  const { data, error } = useAreaSearchQuery();

  React.useEffect(() => {
    // Reset sessions and re-fetch
    setFetchedSessions([]);
    fetchSessions({ variables: filters });
  }, [filters, areaId, fetchSessions]);

  const nonMemberSessions = fetchedSessions.filter(
    (session) => session?.onlyMember === false
  );

  React.useEffect(() => {
    if (pendingFetch) {
      const delay = setTimeout(() => {
        setLoadingMore(false);
        setPendingFetch(false);
      }, 3000); // tweak this duration as needed for smoothness

      return () => clearTimeout(delay);
    }
  }, [fetchedSessions.length, pendingFetch]);

  const handleLoadMore = () => {
    if (!loadingMore && more) {
      setLoadingMore(true);
      setPendingFetch(true);
      setLastResultsCount(fetchedSessions.length);

      fetchSessions({
        variables: {
          ...filters,
          from: _.last(fetchedSessions)?.startedAt,
        },
      });
    }
  };





  if (error || errors) return <UI.Error error={error || errors} />;
  if (!data) return <UI.Loading />;

  return (
    <UI.Box {...props}>
      <UI.Container>
        <UI.Typography
          variant="h2"
          sx={{ my: 2, textAlign: "center", fontStyle: "normal" }}
        >
          Find sessions in your area
        </UI.Typography>
      </UI.Container>

      <UI.Container>
        <UI.FormControl sx={{ width: { xs: "100%", md: "50%" } }}>
          <UI.InputLabel id="area-select-label">Areas</UI.InputLabel>
          <UI.Select
            id="area-select-label"
            value={areaId}
            label="Area"
            onChange={(e) => {
              const selected = e.target.value;
              setAreaId(selected);
              setFilters((current) => ({
                ...current,
                areaIds: selected === "all" ? [] : [selected],
              }));
              handleAreaClick();
            }}
            sx={{ mb: 3 }}
          >
            <UI.MenuItem value="all">All Areas</UI.MenuItem>
            {data?.areas.map((area) => (
              <UI.MenuItem key={area.id} value={area.id}>
                {area.name}
              </UI.MenuItem>
            ))}
          </UI.Select>
        </UI.FormControl>
      </UI.Container>

      {nonMemberSessions.length === 0 && (
        <UI.Typography
          variant="h5"
          sx={{ mb: 2, textAlign: "center", fontStyle: "normal" }}
        >
          Sorry, no sessions in your area
        </UI.Typography>
      )}

      {/* Infinite Scroll for mobile */}
      {!md && (
        <InfiniteScroll
          hasMore={more}
          loadMore={fetchMore}
          initialLoad={false}
          loader={<UI.InfiniteScrollLoader key="loader" more={!!more} />}
        >
          <UI.Grid container spacing={3}>
            {nonMemberSessions.map((session, index) => (
              <UI.Grid key={index} item xs={12}
              sx={{
                animation: 'fadeIn 0.3s ease-in',
                '@keyframes fadeIn': {
                  from: { opacity: 0, transform: 'translateY(10px)' },
                  to: { opacity: 1, transform: 'translateY(0)' },
                },
              }}
              >
                <UI.ListingCard
                  january
                  listing={session}
                  easySignUp={easySignUp}
                />
              </UI.Grid>
            ))}
          </UI.Grid>
        </InfiniteScroll>
      )}

      {/* Grid with Load More for desktop */}
      {md && (
        <>
          <UI.Grid container spacing={3}>
            {nonMemberSessions.map((session, index) => (
              <UI.Grid key={index} item xs={12} md={6}

              >
                <UI.ListingCard
                  january
                  listing={session}
                  easySignUp={easySignUp}
                />
              </UI.Grid>
            ))}
          </UI.Grid>

          {more && (
            <UI.Box sx={{ textAlign: "center", mt: 2 }}>
        <UI.Button
  variant="text"
  onClick={handleLoadMore}
  disabled={loadingMore}
  sx={{
    fontSize: "30px",
    textTransform: "uppercase",
    fontStyle: "italic",
    position: "relative",
  }}
>
  {loadingMore ? (
    <>
      Loading...
      <UI.CircularProgress
        size={20}
        sx={{
          color: "primary.main",
          position: "absolute",
          right: -30,
          top: "50%",
          transform: "translateY(-50%)",
        }}
      />
    </>
  ) : (
    "Load More"
  )}
</UI.Button>


            </UI.Box>
          )}
        </>
      )}
    </UI.Box>
  );
};

export default SessionSearch;
