
import * as UI from "@goodgym/components";
import Banner from "@goodgym/components/Banner";
import IconCards from "@goodgym/components/IconCards";
import * as T from "@goodgym/graphql/types";
import FAQ from "../components/FAQ";
import MidBanner from "../components/MidBanner";
import NewsQuotes from "../components/NewsQuotes";
import AboutMission from "./components/AboutMission";
import GetInvolvedCard from "./components/GetInvolvedCard";
import { FaqData, whyHelp } from "./data";
import * as Images from "./images";
import { MissionBanner, MissionBanner2 } from "./images";
import { useAuth } from "@goodgym/auth";

import Card from "./components/Card";

const AboutMissionPage = () => {

  const { runner } = useAuth();

  const buttonText = runner
    ? runner.needsToGetMissionVerified
      ? "Get Mission Verified"
      : "Find a Mission"
    : "Sign Up";
  const buttonLink = runner
    ? runner.needsToGetMissionVerified
      ? "/my/missions/verification"
      : "/v3/sessions?type=Mission"
    : "/users/sign_up";

  const { data: info } = T.useHomePageQuery();

  const numberOfMissions = info?.stats.missions;

  return (
    <UI.Box sx={{}}>
      <Banner
        title="Help older people with practical tasks"
        image={Images.MissionHeader}
        buttonText={buttonText}
        linkForButton={buttonLink}
      />
      <AboutMission />
      <UI.Container
        sx={{
          display: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card />
      </UI.Container>
      <UI.Container>
        <UI.Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {whyHelp.map((element, index) => (
            <IconCards
              key={index}
              image={element.image}
              tagline={element.tagline}
              bold={true}
            />
          ))}
        </UI.Grid>
      </UI.Container>

      <MidBanner
        numberOfDeeds={numberOfMissions}
        badge={Images.BadgeMission}
        subtitle={"Missions completed"}
      />
      <UI.Box
        sx={{
          backgroundImage: `url(${MissionBanner2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "600px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          aspectRatio: "2/3",
        }}
      />
      <GetInvolvedCard />

      <UI.Container sx={{ mt: 10 }}>
        <UI.Grid
          container
          spacing={7}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <UI.Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <UI.Box sx={{ display: "flex" }}>
              <UI.Typography variant="h2" sx={{ mr: 1, mt: 1 }}>
                ||
              </UI.Typography>
              <UI.Typography variant="h5" sx={{ mt: 2, fontWeight: "normal" }}>
                Many years ago I used to be a gardener full time, my aim now is
                just to have a nice space, nothing that fancy. I can look after
                the pots, but just need some help with other things.
                <br />
                <br />
                <strong>
                  I mostly want it sorted, so I can look out there and bring
                  back the memories. We had such fond times out there, but it is
                  hard to remember what they were like when it is in this mess.
                </strong>
              </UI.Typography>
            </UI.Box>

            <UI.Typography
              variant="body1"
              color={"primary.main"}
              sx={{ mt: 2, textAlign: "right" }}
            >
              Mrs G, mission beneficiary
            </UI.Typography>
          </UI.Grid>
          <UI.Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <UI.Box sx={{ display: "flex" }}>
              <UI.Typography variant="h2" sx={{ mr: 1, mt: 1 }}>
                ||
              </UI.Typography>
              <UI.Typography variant="h5" sx={{ mt: 2, fontWeight: "normal" }}>
                By the end of the mission, there were 5 of us as Ms G was so
                excited with her garden being transformed. She began planting
                some vegetables herself, and became very strict about where she
                wanted the plants. <br />
                <br />
                <strong>
                  It was nice to see her become more confident in the space of 2
                  hours.
                </strong>
              </UI.Typography>
            </UI.Box>

            <UI.Typography
              variant="body1"
              color={"primary.main"}
              sx={{ mt: 2, textAlign: "right" }}
            >
              GoodGymer
            </UI.Typography>
          </UI.Grid>
        </UI.Grid>
      </UI.Container>

      <UI.Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 4,
        }}
      >
        <UI.Card sx={{ width: "70vw" }}>
          <UI.CardHeader
            sx={{
              backgroundImage: `url(${MissionBanner})`,
              height: "340px",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              aspectRatio: "5/3",
            }}
          />
          <UI.Box
            sx={{
              width: "100%",
              borderTop: "10px solid #E11018",
            }}
          />
          <UI.CardContent sx={{ textAlign: "center" }}>
            <UI.Box>
              <UI.Typography variant="h4" sx={{ mr: 1, mt: 1 }}>
                Work with an older person who needs help?
              </UI.Typography>
              <UI.Typography
                variant="h5"
                sx={{ mt: 2, mb: 2, fontWeight: "normal" }}
              >
                Organisations supporting older people can request help with
                practical tasks for people aged over 50.
              </UI.Typography>
            </UI.Box>

            <UI.Button href="/v3/request-a-mission">
              Request a task
            </UI.Button>
          </UI.CardContent>
        </UI.Card>
      </UI.Container>

      <UI.Box sx={{ mt: 6, background: "#E9E9E9", p: 2 }}>
        <UI.Container sx={{}}>
          <UI.Typography variant="h4" sx={{ my: 2 }}>
            What people say about our community
          </UI.Typography>
          <NewsQuotes />
        </UI.Container>
      </UI.Box>
      <FAQ info={FaqData} />
      <UI.Box
        sx={{
          background: "#E11018",
          height: "200px",
          p: 4,
          mt: 5,
          mb: { xs: 6, md: 0 },
        }}
      >
        <UI.Container sx={{ textAlign: "center" }}>
          <UI.Typography
            variant="h2"
            color="neutral.light"
            sx={{ my: 2, textAlign: "center", fontStyle: "normal" }}
          >
            Find missions
          </UI.Typography>
          <UI.Container sx={{ textAlign: "center" }}>
            <UI.Button href="/v3/sessions?type=Mission" sx={{ mb: 3 }}>
              Missions
            </UI.Button>
          </UI.Container>
        </UI.Container>
      </UI.Box>
    </UI.Box>
  );
};

export default AboutMissionPage;
