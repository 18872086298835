import * as T from "@goodgym/graphql/types";
import _ from "lodash";
import * as u from "@goodgym/util";
import {
  Listing,
  ClientSideFilters,
  Session,
  TaskRequest,
  State,
  Filters,
  EnabledSections,
} from "./types";

/*
 * a unique identifier to avoid issues with id clashes
 */
export const identifier = (i: Listing) => `${i.__typename}-${i.id}`;

/*
 * We treat the earliest potential time slot of a task request
 * as its "start time". For sessions it's much easier, as they
 * have an unambiguous start time.
 */
export const getStartTime = (
  item: Pick<TaskRequest, "potentialTimes"> | Pick<Session, "startedAt">
) => {
  return "startedAt" in item ? item.startedAt : item.potentialTimes[0];
};

/*
 * Helper function for when we want to group items by day
 */
export const getStartDay = (item: Listing) =>
  u.time.startOfDay(getStartTime(item));

/*
 * Sessions are available if they don't have a registerMax,
 * or they do, but have fewer signups than the maximum.
 * we assume task requests are always available
 * as otherwise they would have been confirmed by the ops support
 * team and turned into sessions
 */
export const filterAvailable = (item: Listing) => {
  if (item.__typename === "Session" && item.registerMax) {
    return item.signups.length < item.registerMax;
  } else {
    return true;
  }
};

/*
 * Get the type of the session, in PascalCase.
 * Task requests are assumed to only be for missions.
 */
export const getType = (item: Listing) => {
  if (item.__typename === "Session") {
    return u.pascalCase(item.type);
  } else {
    return "Mission";
  }
};

/**
 * Get a distinct list of types of session
 */
export const getAvailableTypes = (items: Listing[]) => {
  return _.uniq(_.map(items, getType));
};

/**
 * Check whether the given item is of one of the passed in types.
 */
export const filterType = (types: string[], item: Listing) => {
  return types.length === 0 ? true : types.includes(getType(item));
};

/*
 * When filtering by time of day we check whether the session start time
 * matches any of the times of day that are excluded from the filter,
 * and return false if so.
 * For task requests, we check whether _any_ of the potential times fall
 * in times of day that aren't excluded in the filter.
 */
// export const filterTimeOfDay = (
//   timeOfDay: ClientSideFilters["timeOfDay"],
//   item: Listing
// ) => {
//   if (timeOfDay.length === 0) return true; // no filter

//   const valid = (t: Date) => {
//     if (timeOfDay.indexOf("MORNING") === -1 && u.time.getHours(t) < 12) {
//       return false;
//     } else if (
//       timeOfDay.indexOf("AFTERNOON") === -1 &&
//       u.time.getHours(t) >= 12 &&
//       u.time.getHours(t) < 17
//     ) {
//       return false;
//     } else if (
//       timeOfDay.indexOf("EVENING") === -1 &&
//       u.time.getHours(t) >= 17
//     ) {
//       return false;
//     } else {
//       return true;
//     }
//   };
//   if (item.__typename === "Session") {
//     return valid(item.startedAt);
//   } else if (item.__typename === "TaskRequest") {
//     return !!_.find(item.potentialTimes, (t) => valid(t));
//   }
// };

/*
 * When filtering by day of week/weekend, we check the startTime
 * of sessions and every potential time slot of task requests.
 * Due to the way we sort task requests all the potential time slots
 * of a task request passed to this function should be on the same day,
 * so technically we should only need to check the first. But just in
 * case we change the calling code, we'll check every potential time
 * here, so it doesn't break in a confusing way should that happen.
 */
// export const filterDayOfWeek = (
//   dayOfWeek: ClientSideFilters["dayOfWeek"],
//   item: Listing
// ) => {
//   if (dayOfWeek.length === 0) return true; // no filter

//   const valid = (t: Date) => {
//     if (dayOfWeek.indexOf("WEEKEND") === -1 && u.time.isWeekend(t)) {
//       return false;
//     } else if (dayOfWeek.indexOf("WEEKDAYS") === -1 && !u.time.isWeekend(t)) {
//       return false;
//     } else {
//       return true;
//     }
//   };

//   if (item.__typename === "Session") {
//     return valid(item.startedAt);
//   } else if (item.__typename === "TaskRequest") {
//     return !!_.find(item.potentialTimes, (t) => valid(t));
//   }
// };

export const filterNonDeclined = (item: Listing) => {
  if (item.__typename === "Session") {
    return true;
  } else if (item.__typename === "TaskRequest") {
    return !item.declined;
  }
};

/*
 * Check each filter in turn. If no filter fails, the item "passes" the filters.
 */
export const applyFilters = (filters: ClientSideFilters , item: Listing | any) => {
  if (u.time.isBefore(getStartTime(item), filters.from)) {
    return false;

  } else if (!filterType(filters.types, item)) {
    return false;

  } else {
    return true;
  }
};

/*
 * We group sessions and task requests by day on the feed and we also group
 * task requests potential time slots by day too.
 */
export const groupByDay = (items: Listing[]): [Date, Listing[]][] => {
  const grouped = _.groupBy(items, (item) => getStartDay(item).getTime());
  return _.map(grouped, (items, date) => [new Date(parseInt(date)), items]);
};

/*
 * Filter sessions to show on the map, either the same day, same week, or same month.
 */
export const filterForMap = (
  state: Pick<State, "items" | "more" >
) => {
  // exclude listings without a lat/lng
  const listings = _.filter(
    state.items,
    (item) => u.geo.center(item).lat
  ) as Listing[];

  if (listings.length === 0) {
    return { items: [], more: false };
  } else {
    

    return {
      items: listings,
      more: state.more 
    };
  }
};

/**
 * Given a task request which may have time slots on multiple days,
 * return an array of duplicates, one for each day the task request
 * has time-slots on, with the potentialTimes field replaced with the time
 * slots for that day only.
 */
export const flattenTaskRequest = (tr: TaskRequest): TaskRequest[] => {
  const timesByDay = _.groupBy(tr.potentialTimes, (t) =>
    u.time.startOfDay(t).getTime()
  );
  return _.map(timesByDay, (potentialTimes, _day) => ({
    ...tr,
    potentialTimes: u.time.sort(potentialTimes),
  }));
};

/*
 * Combine the list of sessions and task requests returned from the server into
 * one list, interleaving them in such a way that they are in chronological order.
 *
 * To make sure we always show the combined list of sessions and task requests
 * in order, we calculate a "cutoff" date based on the two sets of results
 * and discard any results after this cutoff. The last session and task request
 * before the cutoff becomes the "cursor" with which we request the next page
 * of sessions and task requests respectively.
 *
 * To understand why we need to do this, consider the following situation.
 *
 * Assume we have sessions in the DB that fall on these days:
 * 1. mon 2. mon 3. mon 4. tue 5. wed 6. wed 7. wed 8. wed 9. wed 10. thu
 *
 * And task requsets that fall on these days
 * 1. mon 2. wed 3. thu 4. fri 5. sat 6. sun 7. sun 8. sun 9. sun 10. sun
 *
 * If we request both with a page size of 3, these are the results we'll get:
 *
 * Session - mon
 * Session - mon
 * Task request - mon
 * Session - mon
 * Task request - wed
 * Task request - thur
 *
 * If we then request the next page for both we'll get these results:
 *
 * Session - tue
 * Session - wed
 * Session - wed
 * Task request - fri
 * Task request - sat
 * Task request - sun
 *
 * As you can see, the second page of combined task requests and sessions
 * doesn't follow on from the first page - the sessions should be earlier in
 * the list of results, between the first and second task request.
 *
 * To deal with this, we need to discard the second and third task requests
 * from the first page of results, and request the second page of task requests
 * starting from _monday_, not thursday. So the two pages will look like this:
 *
 * Page one:
 *
 * Session - mon
 * Session - mon
 * Task request - mon
 * Session - mon
 * Task request - wed - discarded
 * Task request - thur - discarded
 *
 * Page two:
 *
 * Session - tue
 * Task request - wed
 * Session - wed
 * Session - wed
 * Task request - thu
 *
 * This means our combined page size will vary, in this example, somewhere between 3 and 6.
 *
 * The cutoff is whichever is the earlier of the latest task request and the
 * latest session - assuming there are more of each to fetch.
 *
 * The exception to this is when there are no more sessions or task requests or both.
 *
 * So, given the sessions and task requests that have been loaded
 * from both the server and the apollo cache, we calculate the cutoff for
 * the ones we want to display (as described above) and discard the rest.
 *
 * We then return the items to display along with the last session and last
 * task request before the cutoff which will be used as cursors to fetch the
 * next pages.
 *
 * One complication: because task requests can have multiple time-slots
 * spanning multiple days, before applying the cutoff, we flatten them into
 * one task request for each day on which it has one or more time slots, with
 * only the time slots for that day included.
 *
 * NB. There are what looks like multiple redundant sorts in the function below.
 * Try to "fix" this at your peril! These sorts are needed to deal with some
 * fiddly edge-cases caught by the property-based tests. If you change them
 * it's likely to make them fail.
 */
export const collate = (data?: T.SessionsFeedQuery) => {
  const cutoff = calculateCutoff(data);

  // get the returned sessions
  const sessions = data?.sessions.results || [];

  // discard any after the cutoff date
  const sessionsBeforeCutoff = _.takeWhile(
    sessions,
    (s) =>
      !cutoff ||
      u.time.isBefore(s.startedAt, cutoff) ||
      u.time.isEqual(s.startedAt, cutoff)
  );

  // get the returned task requests
  const taskRequests = data?.taskRequests.results || [];

  // discard any task requests after the cutoff
  const taskRequestsBeforeCutoff = _.takeWhile(
    taskRequests,
    (tr) =>
      !cutoff ||
      u.time.isBefore(tr.potentialTimes[0], cutoff) ||
      u.time.isEqual(tr.potentialTimes[0], cutoff)
  );

  // duplicate each task request once for each day
  // it has at least one timeslot
  const taskRequestsByDay = _.orderBy(
    _.flatMap(taskRequests, flattenTaskRequest),
    [getStartTime, identifier]
  );

  // discard any task requests after the cutoff
  // (we have to do this again because some task requests may have time slots
  // on days after the cutoff
  const taskRequestsByDayBeforeCutoff = _.takeWhile(
    taskRequestsByDay,
    (tr) =>
      !cutoff ||
      u.time.isBefore(tr.potentialTimes[0], cutoff) ||
      u.time.isEqual(tr.potentialTimes[0], cutoff)
  );

  const items = _.orderBy(
    [...sessionsBeforeCutoff, ...taskRequestsByDayBeforeCutoff],
    [getStartTime, identifier]
  );

  const sessionsCursor = _.last(sessionsBeforeCutoff);

  // the taskRequestsCursor has to be based on the list of task requests
  // *before* they're flattened, or we could accidentally skip records
  const taskRequestsCursor = _.last(taskRequestsBeforeCutoff);

  const cursors = {
    sessions: sessionsCursor,
    taskRequests: taskRequestsCursor,
  };

  const more = !!(data?.sessions.more || data?.taskRequests.more);

  return { items, more, cursors };
};

/**
 * As described above, we calculate a cutoff date that allows us to
 * interleave the two lists of task requests and sessions in such
 * a way that subsequent pages will never load in items out of order.
 *
 * The cutoff is whichever is the *earliest* of the latest session
 * time or latest task request time. There are also some edge cases,
 * which are described inline.
 */
export const calculateCutoff = (data?: T.SessionsFeedQuery) => {
  const lastSessionTime = _.last(data?.sessions.results || [])?.startedAt;
  const lastTaskRequestTime = _.last(data?.taskRequests.results || [])
    ?.potentialTimes[0];

  if (!data?.sessions.more && !data?.taskRequests.more) {
    // There are no more sessions or task requests, no cutoff needed
    return null;
  } else if (data?.sessions.more && !data?.taskRequests.more) {
    // there are no more task requests, so we use the latest session as our
    // "cutoff" - this now works the same as standard cursor-based pagination
    return lastSessionTime;
  } else if (!data?.sessions.more && data?.taskRequests.more) {
    // there are no more sessions, so we use the latest task request as our
    // "cutoff" - this now works the same as standard cursor-based pagination
    return lastTaskRequestTime;
  } else {
    // otherwise, it's whichever is the earliest of the two
    return u.time.isBefore(lastSessionTime, lastTaskRequestTime)
      ? lastSessionTime
      : lastTaskRequestTime;
  }
};

/*
 * Given the state and "cursor" task request and session,
 * we extract the variables that are used by the graphql endpoint.
 */
export const getQueryVariables = (
  enabledSections: EnabledSections,
  filters: Filters,
  cursors: {
    sessions?: Pick<Session, "id" | "startedAt">;
    taskRequests?: Pick<TaskRequest, "id" | "potentialTimes">;
  }
): T.SessionsFeedQueryVariables => {
  const { from, areaIds, postcode, maxDistance, types} = filters;
  return {
    types,
    from,
    limit: 10,
    areaIds: enabledSections.areas ? areaIds : [],
    postcode: enabledSections.postcode ? postcode : null,
    maxDistance: maxDistance,
    sessionsCursorId: cursors.sessions?.id,
    sessionsCursorDate: cursors.sessions?.startedAt,
    taskRequestsCursorId: cursors.taskRequests?.id,
    taskRequestsCursorDate: cursors.taskRequests?.potentialTimes[0],
  };
};
